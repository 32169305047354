
// Default .button class
.button {
    @extend %button;
    display: inline-block;
    padding: 0.5rem 1.5rem;
    transition: $transition;
    font-weight: $font-semi;
    text-transform: lowercase;

    &.dark {
        background: $color-secondary;

        &:hover {
            background: $color-secondary-light;
        }
    }

    &:hover {
        @extend %hover;
    }
    
}
