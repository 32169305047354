// Content styles
.centre {

	.block 
	{
		margin-bottom: $block-margin;
	}

	.content 
	{
	
		// Default paragraphs
		p {
			margin: 0 0 $paragraph-margin;

			&.intro {
				padding-bottom: $paragraph-margin;
				border-bottom: $border-dark;
				color: $color-sub;
				font-size: $font-size-body + 0.3;
			}

			a:not(.button):hover {
				color: section(ifa);
			}
		}
		
		// New default <ul> lists
		* + ul {
			@extend %default-ul;
		}
		
		// New default <ol> lists
		* + ol {
			@extend %default-ol;
		}

	}


	
}

.section-search {
	
	.centre {

		.content {

			* + ul {
				
				// @extend %default-ul;
				margin-left:0;
				padding-left:0;
				list-style: none;
			}
		}
	}

}


.section-library .centre .content p{

	&.intro {

		border-bottom:0;
		padding-bottom: 0;
	}

}
