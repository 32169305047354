//==========  Colors  ==========//

// set our colour variables
// avoid using obvious declarative names as the colours may not always match the name
// e.g, $colour-orange
// 
// we also create lighter and darker tones for use with hover states, etc.

$color-primary: 		#c3cf21; 						// the brand colour
$color-primary-light:	lighten($color-primary, 5%);	// the brand colour - lighter
$color-primary-dark:	darken($color-primary, 5%);		// the brand colour - darker

$color-secondary: 		#333333; 						// the accent colour
$color-secondary-light:	#444;	// the accent colour - lighter
$color-secondary-dark:	darken($color-secondary, 5%);	// the accent colour - darker


$color-tertiary: 		$color-secondary; 						// the accent colour
$color-tertiary-light:	$color-secondary-light;	// the accent colour - lighter
$color-tertiary-dark:	$color-secondary-dark;	// the accent colour - darker

$color-quaternary: 		#f39c4a; 						// the accent colour - orange
$color-quaternary-light:	lighten($color-quaternary, 5%);	// the accent colour - lighter
$color-quaternary-dark:	darken($color-quaternary, 5%);	// the accent colour - darker

//==========  Tones  ==========//

// set grey tones variables
// these will typically be used for borders and form inputs
// again, we also create lighter and darker tones for use with hover states, etc.

$color-lightgrey:		#eee;							// a default light grey
$color-lightgrey-light:	#f3f3f3;						// a default light grey - lighter
$color-lightgrey-dark:	darken($color-lightgrey, 5%);	// a default light grey - darker

$color-midgrey:			#bbb;	 						// a default mid grey
$color-midgrey-light:	lighten($color-midgrey, 5%);	// a default mid grey - lighter
$color-midgrey-dark:	darken($color-midgrey, 5%);		// a default mid grey - darker

$color-darkgrey:		darken(#b6b6b6, 5%);						// a default dark grey
$color-darkgrey-light:	lighten($color-darkgrey, 5%);	// a default dark grey - lighter
$color-darkgrey-dark:	darken($color-darkgrey, 5%);	// a default dark grey - darker

$color-white: 			#fff;							// a default white
$color-black: 			#111;							// a default black

$landing-pages: (
	at-home:      #78b2bb,
	in-business:  #9e8bbf
);

$sections: (
	at-home          		: #78b2bb,
	in-business      		: #9e8bbf,
	legal-advice     		: #9e8bbf,
	human-resources  		: #d4808e,
	financial-advice 		: #f39c4a,
	ifa              		: #f39c4a,

	you-and-your-family 	: #f39c4a,
	you-and-your-business 	: #f7bf89,

	financial-planning  	: $color-midgrey,
	life-insurance   		: #78b2bb,
	saving-for-retirement   : #2c95b5,
	managing-your-wealth   	: darken($color-primary, 6%),
	ready-for-gdpr  	: $color-white
);

//==========  Text colors  ==========//

// set our default text colours for titles, paragraphs, etc.

$color-text:			#464545;								// body text colour
$color-sub:				#717171;								// sub and intro text colour
$color-sub-light:		#b9b8b8;								// sub and intro text colour
$color-title:			$color-secondary;							// heading text colour
