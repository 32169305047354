
//==========  Borders  ==========//

// build our default border style 

$border-size: 1px;
$border-style: solid;
$border-color: $color-lightgrey;

// combine border variables into a default
// this is used throughout our modules as a base
$border: $border-size $border-style $border-color;
$border-dark: $border-size $border-style #dedede;
$border-alpha: $border-size $border-style rgba($color-white, .1);

// set a default border-radius
// this is used in our forms and search styling
$border-radius: 4px;

$borders: (
	default: $border-size $border-style $border-color
);
