// Headings
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	margin: 0 0 $heading-margin;
	color: $color-title;
	font-family: $font-family-title;
	font-weight: $font-normal;
	line-height: 1.3;
}

h1, .h1 {
	font-size: $h1; 
	color: section(ifa);
	text-transform: lowercase;
}

h1:contains('Birkett Long') {
	text-transform: none;
}

h2, .h2 {
	font-size: $h2; 
}

h3, .h3 {
	font-size: $h3; 
	text-transform: uppercase; 
}

h4, .h4 {
	font-size: $h4; 
}

h5, .h5 {
	font-size: $h5; 
}

h6, .h6 {
	font-size: $h6; 
}
