// Increase font size as we move through viewports
// this will also increase our rem values
// comment in desktop and larger for full-width layouts
html { 
	font-size: 85%; 

	@media #{$tabletXL} 	{ font-size: 90%; }
	@media #{$desktop} 		{ font-size: 100%; }
	
}


// Body text
body {
	color: $color-text;
	font-family: $font-family-body;
	font-size: $font-size-body;
	font-weight: $font-normal;
	line-height: 1.7;
}
