
//==========  Import modules  ==========//

// import our global modules
@import '../modules/global/config/_index';

// import our local modules
@import '../modules/core/typography/_index';


//==========  Begin styles  ==========//

// Helper class
.hidden {
	display: none;
}


// Reset
.cms-toolbar,
form,
#mobile-ui,
nav,
.crumbtrails,
.staff-rotator
 {
	@extend .hidden;
}


// Print styles
* {
	color: $color-black;
	background: none;
}

.wrapper {
	padding-bottom: 0 !important;
	padding: 2rem;
}

div[class*="enquiry-form"] {
	@extend .hidden;
}

.cell {
	margin-bottom: $block-margin;

	.content {
		p {
			margin-bottom: 1rem;

			&:first-child {
				font-size: 1.3rem;
				margin-bottom: 1.5rem;
			}
		}
	}
}

.header {

	.menu-toggle,
	.nav {
		@extend .hidden;
	}

}

.button-list li,
.block {
	margin-bottom: 1.5rem;
}

.column {

	&.right,
	&.left {
		@extend .hidden;
	}
}

.staff-attributes {
	@extend .hidden;
}

.pre-footer {
	@extend .hidden;
}

.search-container {
	@extend .hidden;
}

.social-icons,
.footer-links {
	@extend .hidden;
}

.cookies {
	@extend .hidden;
}

#ccc-notify {
	display: none!important;
}
