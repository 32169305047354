
// Clear paragraph styles
p {
	margin: 0;
}

// Reset strong tags
strong {
	font-weight: $font-bold;
}

// Reset hr tags 
hr {
	border: 1px solid darken($border-color, 6%);
	margin: $block-margin 0;
}

blockquote {
	font-size: $h3 + 0.2;
	color: $color-darkgrey;
    margin: 0 0 ($block-margin * 1/3) 0;
    
    // left quote marks
    &:before {
        content: '\201C';
    }
    
    // right quote marks
    &:after {
        content: '\201D';
    }
    
    // reset for p tags that are put in by the CMS editor
    p {
        display: inline;
        margin: 0;
    }

	+ strong {
		display: block;
		text-transform: uppercase;
	}
	
}

img {
	max-width: 100%;
}
