
.iframe-wrapper {
	position: relative;
	width: 100%;
	padding-bottom: 55%;
	margin: $block-margin 0;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: none;
		margin: 0;
		padding: 0;
	}
	
}
